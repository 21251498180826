import { Container, makeStyles, Typography } from '@material-ui/core';
import { NextPage } from 'next';

import { DashboardLayout } from '@/components/layout';
import { withLayout } from '@/hocs';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const DashboardPage: NextPage = () => {
    const classes = useStyles();

    return (
        <Container>
            <div className={classes.paper}>
                <Typography variant="h3">Dashboard</Typography>
                <Typography variant="body1" color="textSecondary">
                    Тут пока ничего нет
                </Typography>
            </div>
        </Container>
    );
};

export default withLayout(DashboardPage, DashboardLayout);
